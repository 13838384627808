import React from 'react';
import {css} from '@emotion/core';
import {useStaticQuery, graphql} from 'gatsby';
import Img from 'gatsby-image';

import {chromeDefaultSearchExtensionLink} from '../../constants';

import MapquestLogoSmall from '../../assets/svg/mapquest-logo-black-small.svg';
import LoadingCircles from '../../assets/svg/loading-circles-blue.svg';

// @ts-expect-error
import ModalOverlay from '../../components/ModalOverlay';
// @ts-expect-error
import Modal from '../../components/Modal';
import MapquestLogo from '../../assets/svg/mapquest-logo-black.svg';
// @ts-expect-error
import Steps from '../../components/Steps';
// @ts-expect-error
import Layout from '../../components/Layout.tsx';
// @ts-expect-error
import SEO from '../../components/Seo.tsx';
// @ts-expect-error
import FooterLight from '../../components/FooterLight';
// @ts-expect-error
import currentBrowserName from '../../util/detectBrowser';
import MarketingLayout from '../../components/MarketingLayout';
import {useCTA} from '../../hooks/useCTA';
import Button from '../../components/Button';

const DirectionsSearch = () => {
    const {isModalOverlayVisible, onCtaClick} = useCTA({
        webStoreLink: chromeDefaultSearchExtensionLink,
        isPrimary: true
    });
    const site = useStaticQuery(graphql`
        query DirectionsSearch {
            background: file(relativePath: {eq: "mq_default_search_background.jpg"}) {
                childImageSharp {
                    fixed(width: 1200, quality: 100) {
                        ...GatsbyImageSharpFixed_noBase64
                    }
                }
            }
            search: file(relativePath: {eq: "search_mock.png"}) {
                childImageSharp {
                    fixed(width: 255, quality: 100) {
                        ...GatsbyImageSharpFixed_noBase64
                    }
                }
            }
            progressbar: file(relativePath: {eq: "progress-bar.png"}) {
                childImageSharp {
                    fixed(width: 483, height: 19, quality: 100) {
                        ...GatsbyImageSharpFixed_noBase64
                    }
                }
            }
            map: file(relativePath: {eq: "mapNewYork.png"}) {
                childImageSharp {
                    fixed(width: 1200, quality: 30) {
                        ...GatsbyImageSharpFixed_noBase64
                    }
                }
            }
        }
    `);

    return (
        <MarketingLayout>
            <Layout>
                <SEO
                    title="Install Mapquest Extension"
                    description="Quickly search step-by-step directions to your destination with MapQuest Directions Search!"
                />
                <section
                    className="top-container"
                    css={css`
                        max-width: 100%;
                        overflow: hidden;
                        height: 100vh;
                        @media (max-height: 500px) {
                            overflow-y: scroll;
                        }
                    `}
                >
                    <Img
                        fixed={site.map.childImageSharp.fixed}
                        style={{
                            height: '100%',
                            width: '100%',
                            position: 'absolute',
                            top: '0',
                            left: '0',
                            zIndex: '0'
                        }}
                        imgStyle={{
                            zIndex: '0',
                            position: 'relative'
                        }}
                    />
                    <Modal maxWidth="750px" innerMargin="10%" marginTop="0px">
                        <div
                            css={css`
                                margin: 1rem 0 1rem;
                            `}
                        >
                            <MapquestLogo />
                        </div>
                        <h1
                            css={css`
                                font-size: 2rem;
                                font-weight: 600;
                                line-height: 2.75rem;
                                letter-spacing: 0.05rem;
                                @media (max-width: 600px) {
                                    font-size: 1.5rem;
                                    line-height: normal;
                                }
                            `}
                        >
                            Quickly search step-by-step directions
                            <br />
                            to your destination with MapQuest Directions Search!
                        </h1>
                        <Steps
                            stepsStyle={css`
                                display: flex;
                                width: 100%;
                                margin-bottom: 0.5rem;
                                p {
                                    text-align: left;
                                }
                                .step {
                                    color: #4c8bf5;
                                    font-weight: 700;
                                    margin-right: 1.5rem;
                                }
                            `}
                            description={['Click "Continue"', `Click "Add to Chrome"`, 'Enjoy MapQuest!']}
                        />
                        <Button
                            text="CONTINUE"
                            ariaLabel="CONTINUE"
                            link={chromeDefaultSearchExtensionLink}
                            onClick={onCtaClick}
                            overrideButtonStyles={`
                        background-color: #4c8bf5;
                        color: #fff;
                        &:hover {
                            background-color: #4c8bce;
                        }
                    `}
                        />
                        <p
                            css={css`
                                font-size: 0.7rem;
                                a {
                                    text-decoration: underline;
                                }
                            `}
                        >
                            By clicking above and installing the Chrome Extension, I accept and agree to the MapQuest{' '}
                            <a
                                href="https://hello.mapquest.com/terms-of-use"
                                target="__blank"
                                rel="noopener noreferrer"
                            >
                                Terms of Service,
                            </a>{' '}
                            <a
                                href="https://system1.com/terms/privacy-policy"
                                target="__blank"
                                rel="noopener noreferrer"
                            >
                                Privacy Policy
                            </a>{' '}
                            {`and understand that the ${currentBrowserName()} Extension will make Yahoo the default search of my browser.`}
                        </p>
                    </Modal>
                </section>
                <FooterLight />
                <ModalOverlay isVisible={isModalOverlayVisible} maxWidth="300">
                    <MapquestLogoSmall />
                    <h2
                        css={css`
                            margin: 0.75rem 0;
                            font-weight: 400;
                            font-size: 16px;
                        `}
                    >
                        Click &lsquo;Add to Chrome&lsquo; button to continue
                    </h2>
                    <LoadingCircles
                        css={css`
                            width: 100px;
                            height: 100px;
                        `}
                    />
                </ModalOverlay>
            </Layout>
        </MarketingLayout>
    );
};

export default DirectionsSearch;
