/* @noflow */
import React from 'react';
import {css} from '@emotion/core';

import MapquestLogoBlackSmall from '../assets/svg/mapquest-logo-black.svg';

const FooterDark = () => {
    return (
        <footer
            css={css`
                position: absolute;
                bottom: 0;
                width: 100%;
                display: flex;
                justify-content: flex-end;
            `}
        >
            <div
                className="footer"
                css={css`
                    background-color: #fff;
                    opacity: 0.61;
                    padding: 1rem;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    z-index: 2;
                    a,
                    span {
                        color: #000;
                        border-right: 1px solid #000;
                        padding: 0 1rem;
                        font-size: 0.75rem;
                    }
                    a:last-child {
                        border-right: none;
                    }

                    @media (max-width: 600px) {
                        width: 100%;
                    }

                    @media (max-height: 725px) {
                        display: none;
                    }
                `}
            >
                <MapquestLogoBlackSmall
                    css={css`
                        width: 100%;
                        max-width: 5.3rem;
                    `}
                />
                <span>©2020 MapQuest</span>
                <a href="https://hello.mapquest.com/terms-of-use" target="_blank" rel="noopener noreferrer">
                    Terms
                </a>
                <a href="https://hello.mapquest.com/attributions" target="_blank" rel="noopener noreferrer">
                    EULA
                </a>
                <a href="https://system1.com/terms/privacy-policy" target="_blank" rel="noopener noreferrer">
                    Privacy Policy
                </a>
            </div>
        </footer>
    );
};

export default FooterDark;
