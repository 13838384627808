/* @noflow */
/**
 * SEO component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from 'react';
import {Helmet} from 'react-helmet';

type Meta = {
    name?: string;
    content: string;
    property?: string;
};

type SeoProps = {
    description?: string;
    lang?: string;
    meta?: Meta[];
    title: string;
};

function SEO({description = '', lang = 'en', meta = [], title}: SeoProps) {
    return (
        <Helmet
            htmlAttributes={{
                lang
            }}
            title={title}
            titleTemplate={`Mapquest Extension | ${title}`}
            meta={[
                {
                    name: `description`,
                    content: description
                },
                {
                    property: `og:title`,
                    content: title
                },
                {
                    property: `og:description`,
                    content: description
                },
                {
                    property: `og:type`,
                    content: `website`
                },
                {
                    name: `twitter:card`,
                    content: `summary`
                },
                {
                    name: `twitter:title`,
                    content: title
                },
                {
                    name: `twitter:description`,
                    content: title
                },
                ...meta
            ]}
        />
    );
}

export default SEO;
