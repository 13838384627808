/* @noflow */
/* eslint-disable camelcase, react/jsx-filename-extension, react/prop-types, react/no-array-index-key */
import React from 'react';
import {css} from '@emotion/core';

const Steps = ({stepsStyle, description}) => {
    return (
        <div
            css={css`
                max-width: 500px;
                margin: 1rem auto;
                display: inline-block;
            `}
        >
            {description.map((text, i) => {
                return (
                    <div css={stepsStyle} key={i}>
                        <p className="step">Step {i + 1}:</p>
                        <p className="direction">{text}</p>
                    </div>
                );
            })}
        </div>
    );
};

export default Steps;
