/* @noflow */
/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React, {DetailedHTMLProps, HTMLAttributes} from 'react';
import {Global, css} from '@emotion/core';
import {globalStyles} from '../constants';

type LayoutProps = {
    children: DetailedHTMLProps<HTMLAttributes<HTMLElement>, HTMLElement>;
};

const Layout = ({children}: LayoutProps) => {
    return (
        <>
            <Global styles={globalStyles} />
            <main
                css={css`
                    height: 100%;
                `}
            >
                {children}
            </main>
        </>
    );
};

export default Layout;
